
@import './colors';

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;

.title {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: $font-medium;
}

.subtitle {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: $font-regular;
}

.bodyText {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: $font-regular;
}

.labelText {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: $font-regular;
}

.labelTextItalic {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: $font-regular;
}

.captionText {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: $font-regular;
}

.captionTextError {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: $font-regular;
  color: $error-red;
}

.captionTextItalic {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: $font-light;
  font-style: italic;
}
