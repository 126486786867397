.upload_button {
    margin: 100px;
    padding: 30px 10px;
    outline: none;
    height: 50px;
    border: 4px dotted #ff7900;
    border-radius: 10px;
    background-color: #fffcf6;
    color: #ff7900;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}