$flush-orange: #ff7900;
$flush-orange14: rgba(#ff7900, 0.14);
$flush-orange5: rgba(#ff7900, 0.05);

$flush-orange30: rgba(#ff7900, 0.3);

$black: rgba(0, 0, 0, 0.87);
$overlay-color: rgba(0, 0, 0, 0.4);
$outer-space: #f2f3f4;
$outer-space70: rgba(#f2f3f4, 0.7);
$pastel-gray: #cbcccb;
$isabellen-gray: #edeeed;
$text-color: rgba(0, 0, 0, 0.73);
$gray: #767676;
$white: #ffffff;
$success-green: #21ba45;
$error-red: rgb(222, 53, 11);
